import classNames from 'classnames';
import { CommonCodeDetailDTO, CommonCodeDTO } from '@server/code/code.models';

export const generateClass = (baseClass: string, className?: string) => {
    let classes = baseClass.split(' ');

    if (className) {
        const additionalClasses = className.split(' ');

        additionalClasses.forEach((cls) => {
            if (cls.startsWith('remove:')) {
                const classToRemove = cls.slice(7); // 'remove:' 접두사 제거
                classes = classes.filter((c) => c !== classToRemove);
            } else {
                classes.push(cls);
            }
        });
    }

    return classNames(classes);
};

export const getCommonCodeKey = (codes: CommonCodeDTO[], value: string) => {
    if (!value) {
        return '';
    }

    return codes.find((code) => code.key === value)?.value || '';
};
